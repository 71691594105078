// src/context/PageVariablesContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AppContextType {
  showAbout: boolean;
  setShowAbout: React.Dispatch<React.SetStateAction<boolean>>;
  showLoader: boolean;
  setShowLoader:React.Dispatch<React.SetStateAction<boolean>>;
  isHomepage: boolean;
  setIsHomepage: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isHomepage, setIsHomepage] = useState(true); 
  const [showAbout, setShowAbout] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  return (
    <AppContext.Provider value={{ showAbout, setShowAbout, showLoader, setShowLoader, setIsHomepage, isHomepage }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppVariables = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('usePageVariables must be used within a PageVariablesProvider');
  }
  return context;
};
