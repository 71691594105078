import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LayoutRoot from '../components/layoutRoot'
import GridList from '../components/widgets/itemGrid'

const DefaultLayout: React.FC<DefaultLayoutProps> = (props: DefaultLayoutProps) => {
  //const { strapiGlobal } = useStaticQuery(graphql`
  //  query {
  //    strapiGlobal {
  //      siteName
  //      siteDescription
  //    }
  //  }
  //`)
  return (
    <LayoutRoot>
      {props.children}
    </LayoutRoot>

  );
  
};




export default DefaultLayout;
