import React from 'react';

const GLoader: React.FC = () => {

  return (
    <div style={{ ...styles.container, opacity: 1 }}>
        <div className="lvbk-inner" style={{ ...styles.inner }}>
        <div className="lvbk-loading-pending" style={{ ...styles.bar, background: 'var(--main-stroke-color)', transform: `scaleX(0)` }}></div>
        </div>
    </div>
  );
};

const styles = {
    container: {
        position: 'relative',
        background: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'opacity 300ms ease',
        flexDirection: 'column',
      } as React.CSSProperties,
      inner: {
        width: 150,
        height: 5,
        border: 'solid 1px var(--main-stroke-color)',
        textAlign: 'center',
        borderRadius: 20,
    
      } as React.CSSProperties,
      bar: {
        height: 4,
        width: '100%',
        //background: 'var(--main-stroke-color)',
        transition: 'transform 200ms',
        transformOrigin: 'left center',
        borderRadius: 20,
        position: 'absolute',
        zIndex: 2,
      } as React.CSSProperties,
      data: {
        display: 'none',
    
        color: 'black',
        fontSize: '0.6em',
        /*fontFamily: `-apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Helvetica Neue", Helvetica, Arial, Roboto, Ubuntu, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,*/
        whiteSpace: 'nowrap',
        position: 'absolute',
      } as React.CSSProperties,
};

export default GLoader;
