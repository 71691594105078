import React, { useRef, useEffect, useState, Suspense } from "react";
import '../styles/main.sass'
import '../styles/style.page.scss'
import Loader1 from "./loader/loader";
import Flower from "./html/items/flower.animation";
import { NavBar } from "./navbar";
import gsap from "gsap";
import { useLocation } from '@reach/router';
import { use100vh } from 'react-div-100vh';
import { DarkModeToggle, Mode } from '@anatoliygatt/dark-mode-toggle';
import styled from "styled-components";
import { useAppVariables } from "../context/AppContext";

const LayoutRoot: React.FC<LayoutRootProps> = (props: DefaultLayoutProps) => {
  const location = useLocation();
  const { showAbout, setShowAbout, showLoader, setShowLoader, setIsHomepage } = useAppVariables();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState<boolean>(true);
  const [index, setIndex] = useState<number>(0);
  const [theme, setTheme] = React.useState<'light' | 'dark'>('light');
  useEffect(() => {
      // Déterminer le thème initial côté client
      if (typeof window !== 'undefined') {
          const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
          setTheme(defaultDark ? 'light' : 'light');
      }
  }, []);
  //useEffect(() => {
  //  if (showLoader == false && loaderVisible == true) {
  //    loaderClick();
  //    setLoaderVisible(!loaderVisible);
  //  }
  //}, [showLoader])

  useEffect(() => {
    // Vérifier si l'URL correspond à la page d'accueil
    const isHomepage = location.pathname === '/';
    setIsHomepage(isHomepage);
  }, [location.pathname, setIsHomepage]);

  //const toggleTheme = () => {
  //    const newTheme = theme === 'light' ? 'dark' : 'light';
  //    setTheme(newTheme);
  //};

  //const mode: Mode = theme === 'dark' ? 'light' : 'light';

  //const show3D = () => {
  //  gsap.to(".lvbk-heads", { y: '-100%', ease: "power2.inOut" });
  //}
  const hide3D = () => { 
    gsap.to(".lvbk-heads", { y: '-200%', ease: "power2.inOut" });
  }
  //const showContent = () => {
  //  gsap.to(".lvbk-tails", { y: '-100%', ease: "power2.inOut" });
  //}
  //const hideContent = () => {
  //  gsap.to(".lvbk-tails", { y: 0, ease: "power2.inOut" });
  //} 

  const hamburgerClick = () => {
    setIsMenuOpen(state => !state);
  };

  const navClick = () => {
    console.debug("navclick")
    //if (!showAbout) {
    //  console.debug('while showAbout is false then we set showAbout to true, so show about is always true when we click on showAbout.')
    //  setShowAbout(true)
    //}
    if (isMenuOpen) {
      console.debug('navclick while isMenuOpen is true, we set isMenuOpen to false then')
      setIsMenuOpen(false)
    }
  };

  // button to show 3D scene.
  //const logoClick = () => {
  //  console.debug("logoclick")
  //  if (isMenuOpen) {
  //    setIsMenuOpen(false)
  //    setShowAbout(false)
  //  } else {
  //    setShowAbout(false);
  //  }
  //};

  useEffect(() => {
    //console.debug('showAbout variable changing', showAbout)
    //hide3D()
    //setShowAbout(true)
  }, [showAbout]);


  const loaderClick = () => {
    //setTimeout(() => {
    //  setLoaderVisible(!loaderVisible);
    //  setShowLoader(false)
    //  setIndex(prevstate => prevstate + 1);
    //  if (loaderVisible) {
    //    gsap.to(".lvbk-heads", { y: '-200%', ease: "power4.inOut" });
    //    gsap.to(".lvbk-loader", { y: '-100%', ease: "power4.inOut" });
    //    gsap.to(".lvbk-tails", { y: '-100%', ease: "power4.inOut" });
    //  } 
    //}, 700);
  };

  //useEffect(() => {
  //    setTimeout(() => {
  //      loaderClick();
  //    }, 1000)
  //  });




  const height = use100vh()!;
  const vhUnit: number | "1vh" = height ? (height / 100) : '1vh';
  const canvasHeight = height && vhUnit !== "1vh" ? (100 * vhUnit) : '100vh';

  //<div>
  //    <MyHamburger eventClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => switchCube(e)} toggle={showAbout}/>    
  //</div>
//  <Div style={{position:'absolute', bottom: '5vh', right: '5vw', zIndex: "9999999" }} onClick={toggleTheme}>
//  <DarkModeToggle mode={mode} size="sm" />
//</Div> 
// 3D Introduction
//<Canvas style={{ height: canvasHeight }} shadows dpr={[1, 2]} > 
//                      <ambientLight intensity={0.7} />
//                      <pointLight position={[0, 20, 10]} intensity={0.8} />
//                      <Suspense fallback={null}>
//                          <Scene 
//                          navIndex={navIndex}
//                          handleSentValue={handleSentValue}
//                          handleSentScrollValue={handleSentScrollValue}
//                          loaderVisible={loaderVisible}
//                          theme={theme}
//                          />
//                      </Suspense>
//                    </Canvas>
//
  return (
    <>
        <div className="layout-root lvbk-primary" data-theme={theme}>
            <div className="lvbk-main-wrapper">
                <Loader1 loaderClick={() => loaderClick()}/>
                <div className="lvbk-nav" style={{ zIndex: 5 }} >
                    <div>
                      <NavBar
                        logoClick={() => navClick()} 
                        navClick={() => navClick()} 
                        hamburgerClick={() => hamburgerClick()} 
                        isMenuOpen={isMenuOpen}
                        toggle={loaderVisible}
                        showAbout={showAbout}
                      />
                    </div>
                    <div>
                        <Flower/>
                    </div>
                </div>
                <div className="lvbk-heads quickfix-heads">
                    
                </div>
                <div className="lvbk-tails">
                    {props.children}
                </div>
            </div>
        </div>
    </>
  );
};


const Div = styled.div`
  position: absolute
  z-index: 99999999
  transform: scale(0.7);
  @media (min-width: 768px) {
    transform: scale(1)
  }
  @media (max-width: 768px) {
    transform: scale(0.7);
    bottom: 0.1rem !important;
    margin-inline-end: 0.375rem !important;
    right: 0 !important;
  }
`

export default LayoutRoot;

