import React from "react"
import styled from "styled-components"

/*const wind1 = keyframes`
0% {  d: path(d="M78.05,32.1C79,30,84,17.76,76.35,8.27,71.47,2.22,63.07-.68,56.6,1,51.44,2.25,51,5.57,45.2,6.4c-6.45.92-8.54-2.82-12.6-.86s-3.47,6.19-8.68,8.86c-2.94,1.5-4,.6-5.27,1.7-7.08,6.07,15.09,42.5,21.78,40.51.85-.25,3.14-1.62,5.28-.68a4.63,4.63,0,0,1,1.53,1.19c3,3.7-4.28,17-4.55,31.83,0,2-.07,3,0,3.91,1.84,26.11-23.36,60.77-23.36,60.77C-12.38,203.46,7.07,290.36,7.07,290.36H10s-18-77,11.58-132.65C38.64,125.58,46,115.38,45.37,89.12c-.14-6.19,6.81-31.83,6.81-31.83s15-4.34,16.51-10.21a4.49,4.49,0,0,1,1.19-2.73c2.15-1.8,5.43,1.14,9.87-.17.2-.06,4.07-1.24,4.43-3.57s-3.11-6.06-6.64-6.3Z")  }
100% {  d: path("M61.28,32.1c.92-2.08,6-14.34-1.7-23.83C54.7,2.22,46.3-.68,39.84,1c-5.17,1.3-5.61,4.62-11.41,5.45-6.45.92-8.54-2.82-12.59-.86s-3.47,6.19-8.69,8.86c-2.94,1.5-4,.6-5.27,1.7C-5.2,22.17,17,58.6,23.67,56.61c.84-.25,3.13-1.62,5.27-.68a4.53,4.53,0,0,1,1.53,1.19c3,3.7,8.12,18.08,7.84,32.95,0,2-.06,2.16,0,3,1.84,26.11-19,59.14-19,59.14C-12.38,202.08,7.07,289,7.07,289H10s-18-77,11.58-132.64c17.1-32.14,20-39.83,19.46-66.09-.14-6.19-5.59-32.95-5.59-32.95s15-4.34,16.51-10.21a4.55,4.55,0,0,1,1.19-2.73c2.15-1.8,5.43,1.14,9.87-.17.2-.06,4.08-1.24,4.43-3.57s-3.11-6.06-6.64-6.3Z") }
`*/

const Wrapper = styled.div`
position: absolute;
bottom: 0;
right: 3%;
display: flex;
flex-direction: column;
align-content: center;
width: 100px;
z-index: 999;
@media (max-width: 768px) {
  bottom: 2em !important;
  width: 50px !important;
  right: 1em !important;
  left: initial !important;
  transform: scaleX(-1);
  div.flower-animation svg {
    height: 100px !important;
  }
}
div.flower-animation svg:hover path#flower_1 {
    d: path("M5.65,289.24c3.08-.82-11.81-46,5.62-67.65a49.34,49.34,0,0,0,5.1-7.15c8.5-14.74,8.21-33.4,4.09-34.73-2.62-.84-6.71,5.37-11.23,12.26-6.56,10-5.07,16-7.41,34.72C-1.93,256.83,3.26,289.88,5.65,289.24Z");
}
div.flower-animation svg:hover path#flower_4 {
    d: path("M9.42,289.32S38,224.1,42.1,203.4c2-9.93,3.14-14.58,4.34-19.4,7.32-29.48,6.19-34.64,15.24-63.35,3.33-10.58,11-18.77,11-18.77s19.37,1.26,24.76-6c1.32-1.77,2.74-5.14,6.38-6.38,2.46-.83,3.44.14,4.6-.76,2.84-2.23-2.11-8.92-.77-17.88,1-6.83,4.49-6.85,4.09-11.74-.67-8-10.62-15.68-16.09-14.81a9.7,9.7,0,0,1-4.34,0A10.15,10.15,0,0,1,87,41.76c-4.07-3.45-11.75,1-27.25,3.46-6.45,1-6.81.55-9.77,1.64-1.37.51-16.54,4.71-14.3,12C41.63,78.4,40.3,85,40.3,85a70.09,70.09,0,0,1,8,1.73c5.48,2,18.64,13.41,18.64,13.41s-4.07,5.8-5.36,10c-2.31,7.44-3.8,10-4.21,11.75-3.83,16.6-11,44.84-14.22,61.43-5.69,29.57-37,104.94-37,104.94Z");
}
div.flower-animation svg:hover path#flower_3 {
    d: path("M4.64,289a226.3,226.3,0,0,0,8.17-56c.61-28.69.91-43,2.81-61.79,3.41-33.75,1.82-37.49,2.25-52.09.15-5,2.81-19.4,2.81-19.4s7.58-4.43,6.64-12c-.47-3.79-11.66-5.56-13.28-1.53-2.17,5.42,3,13,3,13s-.67,13.45-1.46,22.08c-.89,9.67.37,16.67-2.34,46.34-3.51,38.3-2.75,37.23-2.88,64.69-.18,37-8.53,56.72-8.53,56.72Z");
}
div.flower-animation svg:hover path#flower_5 {
    d: path("M61.28,32.1c.92-2.08,6-14.34-1.7-23.83C54.7,2.22,46.3-.68,39.84,1c-5.17,1.3-5.61,4.62-11.41,5.45-6.45.92-8.54-2.82-12.59-.86s-3.47,6.19-8.69,8.86c-2.94,1.5-4,.6-5.27,1.7C-5.2,22.17,17,58.6,23.67,56.61c.84-.25,3.13-1.62,5.27-.68a4.53,4.53,0,0,1,1.53,1.19c3,3.7,8.12,18.08,7.84,32.95,0,2-.06,2.16,0,3,1.84,26.11-19,59.14-19,59.14C-12.38,202.08,7.07,289,7.07,289H10s-18-77,11.58-132.64c17.1-32.14,20-39.83,19.46-66.09-.14-6.19-5.59-32.95-5.59-32.95s15-4.34,16.51-10.21a4.55,4.55,0,0,1,1.19-2.73c2.15-1.8,5.43,1.14,9.87-.17.2-.06,4.08-1.24,4.43-3.57s-3.11-6.06-6.64-6.3Z");
}
div.flower-animation svg path {
    transition: 1s;
    stroke-linecap: round;
    stroke-linejoin: round;
}

`


export default function Flower() {
  
  return ( 

    <Wrapper>
      <div className="flower-animation">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.82 291.33" style={{height: '150px'}}>
          <defs>
            <style>{`.flower{fill:none;stroke:var(--main-color-purple);stroke-miterlimit:10;}`}</style>
          </defs>
            <g id="Flower" data-name="Flower">
            <path id="flower_4" data-name="flower 4" className="flower"   d="M9.42,290.7S38,225.48,42.1,204.79c2-9.94,3.14-14.59,4.34-19.41,7.32-29.47,17.76-32.82,26.81-61.53,3.33-10.58,11-18.77,11-18.77s19.36,1.26,24.76-6c1.32-1.77,2.74-5.14,6.38-6.38,2.46-.83,3.44.14,4.6-.77,2.84-2.23-2.11-8.91-.77-17.87,1-6.83,4.49-6.85,4.09-11.74-.67-8-10.62-15.69-16.09-14.81a9.7,9.7,0,0,1-4.34,0A10.15,10.15,0,0,1,98.52,45c-4.07-3.45-11.75.95-27.25,3.46-6.45,1-6.81.55-9.77,1.64-1.37.51-16.54,4.71-14.3,12,6,19.53,4.67,26.1,4.67,26.1a70.09,70.09,0,0,1,8,1.73c5.48,2,18.64,13.41,18.64,13.41s-4.07,5.8-5.36,10c-2.31,7.44-3.8,10-4.21,11.75-3.83,16.59-22.6,43-25.79,59.62-5.69,29.57-37,104.93-37,104.93Z"/>
                <path id="flower_3" data-name="flower 3" className="flower"  d="M4.64,290.36a226.33,226.33,0,0,0,8.17-56.05c.61-28.69.91-43,2.81-61.79,3.41-33.75,6.2-37.48,6.64-52.08.15-5,2.81-19.41,2.81-19.41s7.57-4.42,6.63-12C31.24,85.25,20,83.47,18.43,87.5c-2.17,5.43,3,13,3,13S20.75,114,20,122.61c-.89,9.66-4,16.67-6.72,46.34-3.51,38.29-2.75,37.22-2.88,64.68-.18,37-8.53,56.73-8.53,56.73Z"/>
            <path id="flower_5" data-name="flower 5" className="flower"  d="M78.05,32.1C79,30,84,17.76,76.35,8.27,71.47,2.22,63.07-.68,56.6,1,51.44,2.25,51,5.57,45.2,6.4c-6.45.92-8.54-2.82-12.6-.86s-3.47,6.19-8.68,8.86c-2.94,1.5-4,.6-5.27,1.7-7.08,6.07,15.09,42.5,21.78,40.51.85-.25,3.14-1.62,5.28-.68a4.63,4.63,0,0,1,1.53,1.19c3,3.7-4.28,17-4.55,31.83,0,2-.07,3,0,3.91,1.84,26.11-23.36,60.77-23.36,60.77C-12.38,203.46,7.07,290.36,7.07,290.36H10s-18-77,11.58-132.65C38.64,125.58,46,115.38,45.37,89.12c-.14-6.19,6.81-31.83,6.81-31.83s15-4.34,16.51-10.21a4.49,4.49,0,0,1,1.19-2.73c2.15-1.8,5.43,1.14,9.87-.17.2-.06,4.07-1.24,4.43-3.57s-3.11-6.06-6.64-6.3Z"/>
                <path id="flower_2" data-name="flower 2" className="flower"  d="M3.64,290.34C11.53,280,35.66,197,33.36,177.61a9,9,0,0,1,1.36-3.7c1.36-2.12,2.52.25,4.8-2,1.32-1.29,2.8-6.46,3.16-13,.23-4.14-10.73-5.65-11.47-2.3-.84,3.81-4.1,12,.1,15.82.81.73.13,2,.14,2.69.64,41.87-29.62,114.51-29.62,114.51S3.52,290.5,3.64,290.34Z"/>
                <path id="flower_1" data-name="flower 1" className="flower"  d="M5.65,290.63c3.08-.82-11.81-46,5.62-67.66a48.76,48.76,0,0,0,5.1-7.15c8.5-14.74,8.21-33.4,4.09-34.72-2.62-.85-6.71,5.37-11.23,12.25-6.56,10-5.07,16-7.41,34.73C-1.93,258.22,3.26,291.27,5.65,290.63Z"/>
            </g>
        </svg>
    </div>
    </Wrapper>


  )
}

