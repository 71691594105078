import React, { useEffect } from 'react';
import  GLoader from './gatsbyLoader';
import Field from '../html/items/field.animation';
import gsap from 'gsap';
import './style.loader.scss';

interface LoaderProps {
  loaderClick: () => void;
}

const Loader1: React.FC<LoaderProps> = (props) => {

  useEffect(() => {
    gsap.to(".lvbk-loading-pending", {
      scaleX: 1,
      duration: 7,
    });
  }, []);

  useEffect(() => {
      setTimeout(() => {
        props.loaderClick();
      }, 700)
  }, []);


  //        <div className="lvbk-loading-field" style={{ zIndex: '2' }}>
  //<Field />
  //</div>
  return (
    <div className="lvbk-loader quickfix-loader">
      <div className="lvbk-loader-container">
        <div className="lvbk-loader-section">
          <div className="lvbk-loader-name lvbk-special-font">
            laurent vbk
          </div>
          <GLoader />
        </div>
      </div>
    </div>
  );
};

export default Loader1;
